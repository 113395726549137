<template>
  <div class="search-page">
    <van-search
      show-action
      v-model="goodsName"
      @search="toSearchDetails"
      @keyup.enter="toSearchDetails"
      shape="round"
      placeholder="请输入搜索关键词"
    >
      <template #left>
        <div @click="onClickLeft()">
          <van-icon name="arrow-left" style="margin-right: 10px" />
        </div>
      </template>

      <template #action>
        <!-- <div class="sousuo-button" @click="toSearchDetails">搜索</div> -->
      </template>
    </van-search>

    <!-- <div class="search-history">
      <div class="history-title">
        <uni-text class="text"><span>热门搜索</span></uni-text>
      </div>
      <div class="history-content">
        <div
          class="history-item"
          v-for="(key, index) in hotKeywordsList"
          :key="index"
          @click="toHistory(key)"
        >
          {{ key }}
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      goodsName: "",
      hotKeywordsList: [],
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      hotKeywords: (state) => state.index.hotKeywords,
    }),
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      let data = {};
      this.$store.dispatch("index/queryHotKeywords", data).then(() => {
        this.hotKeywordsList = this.hotKeywords.result;
        // this.$forceUpdate();
      });
    },

    toHistory(v) {
      this.goodsName = v;
      this.toSearchDetails();
    },

    // 跳转到详情页面
    toSearchDetails() {
      if (this.goodsName == "" || this.goodsName == undefined) {
        // showToast('请输入搜索关键词')
      } else {
        this.$router.push({
          path: "/product_list",
          query: { goodsName: this.goodsName },
        });
      }
    },

    /**
     * 返回主页
     */
    onClickLeft() {
      this.$router.push("/mindex");
    },
  },
};
</script>
<style scoped>
.search-page {
  /* width: 23.4375rem; */
  /* height: 100vh; */
  /* background-color: #fff; */

  /* display: flex; */
  /* align-items: center; */
  /* justify-content: flex-start; */
  /* flex-direction: column; */
}

.search-history {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  margin-top: 1.28125rem;
}

.search-page .search-history .history-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 0.53125rem;
}

.search-history .history-title .text {
  font-size: 0.9375rem;
  font-weight: 700;
  color: #1e2331;
}

.search-history .history-content {
  padding: 0 0.875rem;
  margin-top: 0.625rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.625rem;
  flex-wrap: wrap;
}

.search-history .history-content .history-item {
  color: #71747c;
  font-weight: 400;
  font-size: 0.8125rem;
  background: #f4f7fa;
  border-radius: 0.9375rem;
  padding: 0.375rem 0.875rem;
}
</style>
