<template>
  <div class="module-common pc-common">
    <div>
      <el-row>
        <el-col :span="4">
          <div
            style="height: 1200px; border-radius: 5px; margin: 5px 5px 0px 0px"
            :style="moduleBgStyle"
          >
            <el-menu
              class="m-menu-class"
              :background-color="module.moduleBg.bgColor"
              :text-color="module.moduleBg.fontColor"
              :active-text-color="module.moduleBg.fzColor"
              @open="handleOpen"
              :unique-opened="true"
            >
              <el-submenu
                :index="item.id.toString()"
                v-for="(item, index) in catesList"
                :key="index"
                :name="item.id"
              >
                <template slot="title" style="padding-left: 0px">
                  <span>{{ item.categoryName }}</span>
                </template>

                <el-menu-item-group
                  v-for="(value, index) in item.children"
                  :key="index"
                >
                  <el-menu-item
                    :index="value.id.toString()"
                    style="padding-left: 20px"
                  >
                    <span @click="toCate(value)">
                      {{ value.categoryName }}
                    </span>
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </div>
        </el-col>

        <el-col :span="20">
          <div>
            <van-list
              v-model="loadingState"
              :finished="finished"
              finished-text="没有更多了"
              @load="loadMore"
              style="background-color: #ffffff; padding: 0px 0px 20px 0px"
            >
              <el-row :gutter="20">
                <el-col :span="6" v-for="(v, k, i) in goodsList" :key="i">
                  <div class="goods5-card" @click="toProductDetails(v)">
                    <div style="width: 230px; height: 230px">
                      <el-image :src="v.picUrl"> </el-image>
                    </div>
                    <div class="goods2_content">
                      <div style="height: 45px; overflow: hidden">
                        {{ v.productName }}
                      </div>
                      <div style="margin: 10px 0px">
                        <!-- 商品类型 -->
                        <el-tag
                          effect="dark"
                          size="mini"
                          :color="v.productTypeColor"
                        >
                          {{ v.productTypeText }}
                        </el-tag>
                      </div>
                      <div class="index2_price">
                        <span v-html="$decimalPrice(v.price)"></span>
                        <el-tag size="small" style="float: right">
                          商品编号：{{ v.id }}
                        </el-tag>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </van-list>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { queryProductList } from "@/api/index";

export default {
  props: ["module"],

  name: "HelloWorld",
  data() {
    return {
      showOverlay: true,
      goodsLabelList: [],
      goodsList: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        categoryId: undefined,
        searchKeyword: undefined,
        queryCondition: null,
      },
      goodsName: "",
      goodsStatus: false,
      pageNum: 1,
      pageSize: 10,
      loadingState: false,
      finished: false,
      isFetching: false, // 是否有正在进行中的请求
      catesList: [],
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      producCategorytList: (state) => state.index.producCategorytList,
      productList: (state) => state.index.productList,
    }),
    moduleBgStyle() {
      return {
        background: `${this.module.moduleBg.bgColor}`,
      };
    },
    modulefontStyle() {
      return {
        color: this.module.moduleBg.fontColor,
      };
    },
  },
  created() {
    document.title = "商品列表";

    this.queryParam.categoryId = this.$route.query.categoryId;
    this.queryParam.searchKeyword = this.$route.query.goodsName;

    //获取商品分类
    this.loadCate();
    //获取获取商品列表
    this.loadData();
  },
  methods: {
    //获取商品分类
    loadCate() {
      let data = {};
      this.$store.dispatch("index/queryProducCategorytList", data).then(() => {
        this.catesList = this.producCategorytList.list;
      });
    },

    //查询商品列表
    loadData() {
      this.isFetching = true;
      new Promise((resolve, rejust) => {
        queryProductList(this.queryParam)
          .then((res) => {
            //主站
            this.goodsList = this.goodsList.concat(res.result.productList);

            this.total = res.result.total;
            if (res.result.productList.length == 0) {
              this.finished = true;
            }
            this.loadingState = false;
            this.isFetching = false;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    toPCate(k) {
      this.loadingState = true;
      this.finished = false;

      this.goodsList = [];
      this.queryParam.pageNum = 1;
      this.queryParam.pageSize = 10;
      this.queryParam.categoryPid = k.id;
      this.queryParam.categoryId = undefined;
      this.loadData();
    },

    toCate(v) {
      this.loadingState = true;
      this.finished = false;

      this.goodsList = [];
      this.queryParam.pageNum = 1;
      this.queryParam.pageSize = 10;
      this.queryParam.categoryId = v.id;
      this.queryParam.categoryPid = undefined;
      this.loadData();
    },

    /**
     * 加载更多
     */
    loadMore() {
      if (this.isFetching) {
        // 如果有正在进行中的请求，则不继续发起新的请求
        return;
      }
      this.queryParam.pageNum = this.queryParam.pageNum + 1;
      this.loadingState = true;
      this.loadData();
    },

    //跳转商品详情界面
    toProductDetails(v) {
      this.$router.push({
        path: "product_detail",
        query: { product_id: v.id },
      });
    },
  },
};
</script>
<style>
.goods5-card {
  position: relative;
  display: block;
  background-color: #fff;
  padding: 10px;
  overflow: hidden;
  border: 1px solid #f2f2f2;
  /* margin-top: 10px; */
}

.goods5-card .goods2_content {
  line-height: 24px;
}

.goods5-card .goods2_content .index2_price {
  height: 24px;
  font-size: 18px;
  align-items: baseline;
  color: #ff4f42;
  /* font-size: 14px;
  display: flex;
  color: #f44336;
  align-items: center;
  margin-bottom: 20px; */
}

.goods5-card:hover {
  border: 1px solid #14d3b7;
}

/* 组件样式 */
.m-menu-class .el-submenu__title {
  padding-left: 10px !important;
}

.m-menu-class .el-submenu__icon-arrow {
  right: 10px;
}

.m-menu-class .el-submenu .el-menu-item {
  min-width: 0px;
}
</style>
