<template>
  <!-- :style="[
      !module.base.m ? { 'margin-top': '0' } : {},
      {
        padding:
          '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px',
      },
    ]" -->
  <div class="module-common pc-common">
    <div class="model_guanggao">
      <div
        class="model_guanggao_img"
        v-for="(item, index) in bannerList"
        :key="index"
      >
        <div class="btn-wrap">
          <a :href="item.clickUrl" target="_blank">
            <img alt="" :src="item.urlPath" class="model_guanggao_Banner" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryBannerList } from "@/api/index";

export default {
  props: ["module"],
  data() {
    return {
      bannerList: [],
    };
  },

  created() {
    //获取轮播图
    this.getBannerList();
  },
  methods: {
    /**
     * 查询轮播图
     */
    getBannerList() {
      let data = { banType: "PC_2" };

      queryBannerList(data).then((response) => {
        if (response.status == 0) {
          this.bannerList = response.result;
        }
      });
    },
  },
};
</script>

<style>
.model_guanggao {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 126px;
}

.model_guanggao_Banner {
  border-radius: 5px;
  width: 316px;
  height: 126px;
  -webkit-box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
}
</style>
