<template>
  <div>
    <van-nav-bar
      left-text="返回"
      title="收益明细"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="content">
      <!-- <div class="filter">
        <input type="date" v-model="startDate" class="date-input"> 至 <input type="date" v-model="endDate" class="date-input">
        <button @click="getProfitDetails" class="filter-btn">筛选</button>
      </div> -->
      <table class="profit-table">
        <thead>
          <tr>
            <th>时间</th>
            <th>金额</th>
            <th>类型</th>
            <th>来源商品</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(v, key, index) in tableData" :key="index">
            <td>{{ v.datelineReadable }}</td>
            <td>{{ v.amount }}</td>
            <td>{{ v.statusText }}</td>
            <td>{{ v.productName }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import "@/assets/css/style.css";
export default {
  name: "HelloWorld",
  data() {
    return {
      orderLists: [],
      card: [],
      cdKey: "",
      cdKeyStatus: false,
      orderActive: 0,
      dictList: [],
      orderList: [],
      queryParam: {
        orderId: undefined,
      },
      tableData: [],
    };
  },

  computed: {
    ...mapState({
      list: (state) => state.commission.list,
    }),
  },
  created() {
    document.title = "收益明细";
    this.loadData();
  },
  methods: {
    loadData() {
      let data = { offset: 0, limit: 10 };
      this.$store.dispatch("commission/list", data).then(() => {
        this.tableData = this.list;
        console.log(this.tableData);
        // this.$forceUpdate();
      });
    },

    // // 查询商品列表
    // getDictList() {
    //   this.queryParam.dictType = 'order_status'
    //   dictList(this.queryParam).then((response) => {
    //     this.dictList = response.data.rows
    //   })
    // },

    // queryOrderList(value, k){
    //   this.orderActive = k
    //   orderInfoByType().then((response) => {
    //     this.orderList = response.rows
    //   })
    // },

    // /**
    //  * 查询卡密
    //  */
    // queryCdkey(v){
    //       this.cdKeyStatus = true
    //       this.card = JSON.parse(v.card)
    //   // outerOrder(this.queryParam).then((response) => {
    //   //   //数据加载
    //   //   console.log(response.data.data)
    //   //   if(response.data.data!=''){
    //   //     this.cdKey = response.data.data
    //   //   }
    //   // })
    // },

    //  //复制卡号
    // cardCopy(){
    //       const inputNode = document.createElement('input')
    //       inputNode.setAttribute('readonly', 'readonly')
    //       inputNode.value = this.card.cardNo
    //       document.body.appendChild(inputNode)
    //       inputNode.select()
    //       document.execCommand('Copy')
    //       inputNode.className = 'oInput'
    //       inputNode.style.display = 'none'
    //       Toast('卡号复制成功')
    // },

    // //复制密码
    // passWordCopy(){
    //       const inputNode = document.createElement('input')
    //       inputNode.setAttribute('readonly', 'readonly')
    //       inputNode.value = this.card.cardPassword
    //       document.body.appendChild(inputNode)
    //       inputNode.select()
    //       document.execCommand('Copy')
    //       inputNode.className = 'oInput'
    //       inputNode.style.display = 'none'
    //       Toast('卡密复制成功')
    // },

    // //复制Ckd
    // copyCkd(){
    //    var copyInput = document.createElement('input')
    //   //val是要复制的内容
    //   copyInput.setAttribute('readonly', 'readonly')
    //   copyInput.setAttribute('value', this.cdKey)
    //   document.body.appendChild(copyInput)
    //   copyInput.select()
    //   try {
    //     var copyed = document.execCommand('copy')
    //     if (copyed) {
    //       document.body.removeChild(copyInput)
    //       Toast('复制成功')
    //     }
    //   } catch {
    //       Toast.fail('复制失败,请检查客服')
    //   }
    // },

    // /**
    // 查询订单详情
    //  */
    // queryOrderDetails(v) {
    //   console.log(v);
    //   this.$router.push({
    //     path: "/order_detail",
    //     query: {
    //       orderId: v.id,
    //     },
    //   });
    // },

    //  //跳转到首页
    // toIndex() {
    //   this.$router.push('/')
    // },
    onClickLeft() {
      this.$router.push("/my");
    },
  },
};
</script>
<style>
.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.date-input {
  height: 28px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 0 8px;
  font-size: 14px;
  box-sizing: border-box;
}
.filter-btn {
  height: 28px;
  border-radius: 4px;
  background-color: #007aff;
  color: #fff;
  font-size: 14px;
  padding: 0 12px;
  border: none;
  outline: none;
  cursor: pointer;
}
.profit-table {
  border-collapse: collapse;
  width: 100%;
}
.profit-table th,
.profit-table td {
  text-align: center;
  padding: 8px;
  border: 1px solid #ccc;
}
.profit-table th {
  background-color: #eee;
  font-weight: bold;
}
</style>