<template>
  <div class="module-common pc-common">
    <div class="index-3" v-for="(value, key, index) in catesList" :key="index">
      <div class="top">
        <div class="border fl"></div>
        <p class="fl">{{ value.categoryName }}</p>
        <span>{{ value.categoryName }}</span>
      </div>
      <div class="bottom bottom-new2">
        <ul>
          <li
            v-for="(goods, key, index) in value.children"
            :key="index"
            @click="toProductList(goods)"
          >
            <a>
              <el-image :src="goods.prcUrl" class="goodsImg"> </el-image>
              <p>{{ goods.categoryName }}</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: ["module"],
  components: {},
  data() {
    return {
      loginStatus: false,
      catesList: [],
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      producCategorytList: (state) => state.index.producCategorytList,
    }),
  },
  created() {
    //查收商品分类
    this.getCateList();
  },
  methods: {
    /**
     * 查询商品分类
     */
    getCateList() {
      // 首页tab商品
      let data = { offset: 0, limit: 10 };
      this.$store.dispatch("index/queryProducCategorytList", data).then(() => {
        this.catesList = this.producCategorytList.list;
      });
    },

    /**
     * 跳转到商品列表
     */
    toProductList(value) {
      console.log(value);
      this.$router.push({
        path: "pcate",
        query: { categoryId: value.id },
      });
    },
  },
};
</script>

<style>
.index-3 {
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  margin-top: 18px;
  padding-left: 10px;
}

.index-3 .top {
  line-height: 60px;
  border-bottom: 1px dashed #e5e5e5;
  font-size: 24px;
  color: #222222;
}

.index-3 .top .border {
  width: 6px;
  height: 23px;
  background-color: #663fe7;
  border-radius: 3px;
  margin-top: 18px;
  margin-right: 10px;
  margin-left: 15px;
}

.index-3 .top span {
  font-size: 14px;
  color: #999999;
  margin-left: 25px;
}

.index-3 .bottom {
  margin: 15px 11px;
  padding: 10px;
}

.index-3 .bottom ul {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.index-3 .bottom ul li {
  width: 20%;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
}

.index-3 .bottom ul li a {
  color: #663fe7;
  width: 85%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.index-3 .bottom-new2 {
  /* margin: 25px 0px; */
}

.index-3 .bottom-new2 ul li {
  width: 20%;
  margin-bottom: 30px;
}

.index-3 .bottom-new2 ul li a {
  display: flex;
  align-items: center;
  color: #222;
  width: 100%;
  padding: 10px;
}

.index-3 .goodsImg {
  margin-right: 10px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  box-shadow: 0 5px 15px rgba(0, 184, 255, 0.2);
  background: #fff;
  /* margin-bottom: 8px; */
}

.index-3 .bottom-new3 {
  margin: 25px 0px;
}

.index-3 .bottom-new3 ul li {
  width: 33%;
}

.index-3 .bottom-new3 ul li a {
  color: #222;
}

.index-3 .bottom-new3 ul li a img {
  width: 324px;
  height: 151px;
}

.fl {
  float: left;
  font-size: 18px;
}

.fr {
  float: right;
}
</style>
