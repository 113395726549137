<template>
  <div style="background-color: #f5f7fa">
    <van-nav-bar
      left-text="返回"
      title="查询订单"
      left-arrow
      @click-left="onClickLeft"
    />

    <van-search
      v-model="rechargeAccount"
      placeholder="请输入要查询的充值账号"
      show-action
    >
      <template #action>
        <van-button
          icon="search"
          type="primary"
          round
          size="small"
          @click="loadData"
        />
      </template>
    </van-search>

    <van-list finished-text="没有更多了">
      <div style="padding: 5px 10px 10px 10px">
        <div
          class="scroll-item"
          v-for="(v, key, index) in orderLists"
          :key="index"
        >
          <div class="item-top">
            <div class="order-text">
              订单号：{{ v.serialNum }}
              <van-icon name="label" />
            </div>
            <span :style="{ color: v.statusTextColor }">
              {{ v.statusText }}
            </span>
          </div>
          <div class="center">
            <van-image
              width="100"
              height="100"
              :src="v.orderFormProduct.picUrl"
            />

            <div class="center-right">
              <span class="center-title">
                <span>{{ v.orderFormProduct.productName }}</span>
              </span>

              <div class="center-num">
                <span class="money" style="font-size: 14px"
                  >￥{{ v.orderFormProduct.productPrice }}</span
                >

                <span class="number">
                  <span>x{{ v.orderFormProduct.quantity }}</span>
                </span>
              </div>
            </div>
          </div>
          <div class="order-bottom">
            <div class="order-bottom-left">
              合计：<span>
                <span>
                  <span class="money">
                    <span>{{ v.amountFinally }}</span>
                  </span>
                </span>
              </span>
            </div>
            <div class="order-bottom-right">
              <van-button
                type="info"
                round
                size="small"
                @click="queryOrderDetails(v)"
                >查看详情</van-button
              >
            </div>
          </div>
        </div>
      </div>
    </van-list>

    <van-empty description="请输入要查询的账号" v-if="!this.orderListsStast" />
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import "@/assets/css/style.css";
import { Toast } from "vant";

export default {
  name: "HelloWorld",
  data() {
    return {
      orderListsStast: false,
      showPicker: false,
      rechargeAccount: "",
      orderPopupStatus: false,
      orderLists: [],
      card: [],
      cdKey: "",
      cdKeyStatus: false,
      orderActive: 0,
      dictList: [],
      orderList: [],
      queryParam: {
        orderId: undefined,
      },
      orderId: "",
      desc: "",
      value: "",
      code: "",
      productId: "",
      arr: [],
      options: [
        { value: 0, label: "无理由" },
        { value: 1, label: "质量问题" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      queryOrderForm: "queryOrderForm",
    }),
    ...mapState({
      queryOrderForm: (state) => state.order.queryOrderForm,
    }),
  },
  created() {
    document.title = "我的订单";

    // 查询商品状态类型
    // this.getDictList()
    //查询订单
    // this.loadData();
  },
  methods: {
    loadData() {
      if (this.rechargeAccount == "") {
        Toast.success({
          message: "请输入要查询的充值账号",
        });
        return;
      }
      let data = {
        rechargeAccount: this.rechargeAccount,
      };
      this.$store.dispatch("order/queryOrderForm", data).then(() => {
        this.orderLists = this.queryOrderForm;
        if (this.orderLists.length > 0) {
          this.orderListsStast = true;
        } else {
          Toast("查询结果不存在");
        }
        // this.$forceUpdate();
      });
    },

    onConfirm(value) {
      this.value = value.label;
      this.code = value.value;
      this.showPicker = false;
    },
    // // 查询商品列表
    // getDictList() {
    //   this.queryParam.dictType = 'order_status'
    //   dictList(this.queryParam).then((response) => {
    //     this.dictList = response.data.rows
    //   })
    // },

    // queryOrderList(value, k){
    //   this.orderActive = k
    //   orderInfoByType().then((response) => {
    //     this.orderList = response.rows
    //   })
    // },

    // /**
    //  * 查询卡密
    //  */
    // queryCdkey(v){
    //       this.cdKeyStatus = true
    //       this.card = JSON.parse(v.card)
    //   // outerOrder(this.queryParam).then((response) => {
    //   //   //数据加载
    //   //   console.log(response.data.data)
    //   //   if(response.data.data!=''){
    //   //     this.cdKey = response.data.data
    //   //   }
    //   // })
    // },

    //  //复制卡号
    // cardCopy(){
    //       const inputNode = document.createElement('input')
    //       inputNode.setAttribute('readonly', 'readonly')
    //       inputNode.value = this.card.cardNo
    //       document.body.appendChild(inputNode)
    //       inputNode.select()
    //       document.execCommand('Copy')
    //       inputNode.className = 'oInput'
    //       inputNode.style.display = 'none'
    //       Toast('卡号复制成功')
    // },

    // //复制密码
    // passWordCopy(){
    //       const inputNode = document.createElement('input')
    //       inputNode.setAttribute('readonly', 'readonly')
    //       inputNode.value = this.card.cardPassword
    //       document.body.appendChild(inputNode)
    //       inputNode.select()
    //       document.execCommand('Copy')
    //       inputNode.className = 'oInput'
    //       inputNode.style.display = 'none'
    //       Toast('卡密复制成功')
    // },

    // //复制Ckd
    // copyCkd(){
    //    var copyInput = document.createElement('input')
    //   //val是要复制的内容
    //   copyInput.setAttribute('readonly', 'readonly')
    //   copyInput.setAttribute('value', this.cdKey)
    //   document.body.appendChild(copyInput)
    //   copyInput.select()
    //   try {
    //     var copyed = document.execCommand('copy')
    //     if (copyed) {
    //       document.body.removeChild(copyInput)
    //       Toast('复制成功')
    //     }
    //   } catch {
    //       Toast.fail('复制失败,请检查客服')
    //   }
    // },

    // /**
    // 查询订单详情
    //  */
    queryOrderDetails(v) {
      console.log(v);
      this.$router.push({
        path: "/visitor_order_detail",
        query: {
          orderId: v.id,
        },
      });
    },

    /**
     * 申请订单售后
     */
    applyShouHou(v) {
      this.orderPopupStatus = true;
      this.orderId = v.id;
      this.arr = [
        {
          orderFormProductId: v.orderFormProductList[0].id,
          quantity: v.orderFormProductList[0].quantity,
        },
      ];
    },

    /**
     * 提交申请售后
     */
    handleCreate() {
      if (this.code == "") {
        Toast.fail({
          message: "请选择申请原因",
        });
        return;
      }

      if (this.desc == "") {
        Toast.fail({
          message: "请输入问题描述",
        });
        return;
      }

      this.$store
        .dispatch("refund/create", {
          orderFormProductList: this.arr,
          reasonType: this.code,
          reasionDescription: this.desc,
          id: this.orderId,
        })
        .then(() => {
          Toast.success({
            message: "提交成功",
          });
          this.orderPopupStatus = false;
        });
    },
    //  //跳转到首页
    // toIndex() {
    //   this.$router.push('/')
    // },
    onClickLeft() {
      this.$router.push("/my");
    },
  },
};
</script>
<style>
.my-order {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 23.4375rem;
  height: 100vh;
  background-color: #fff;
  overflow: auto;
}

.my-order .content-box {
  width: 23.4375rem;
  background-color: #f5f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-order .scroll-box {
  width: 23.4375rem;
  height: calc(100vh - 6.25rem);
  position: relative;
  background: #f5f7fa;
  margin-top: 0.625rem;
}

.uni-scroll-div {
  position: relative;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  max-height: inherit;
}

.uni-scroll-div {
  position: relative;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  max-height: inherit;
}

.uni-scroll-div-content {
  width: 100%;
  height: 100%;
}

.my-order .scroll-box .scroll-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 0 0.5625rem;
  gap: 0.9375rem;
}

.scroll-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  /* width: 22.3125rem; */
  height: 12.8125rem;
  background: #ffffff;
  border-radius: 0.46875rem;
  padding: 0 0.6875rem;
  margin-top: 10px;
}

.scroll-item .item-top {
  margin-top: 0.53125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.scroll-item .item-top .order-text {
  color: #9b9fa8;
  font-weight: 400;
  font-size: 0.75rem;
}

.copy-icon {
  width: 0.53125rem;
  height: 0.53125rem;
  position: relative;
  display: inline-block;
  border-color: #9b9fa8;
}

.copy-icon:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0.09375rem;
  border: 0.0625rem solid;
  border-color: inherit;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: #fff;
}

.copy-icon {
  width: 0.53125rem;
  height: 0.53125rem;
  position: relative;
  display: inline-block;
  border-color: #9b9fa8;
}

.scroll-item .item-top .link {
  color: #fe5f4d;
  font-weight: 400;
  font-size: 0.875rem;
}

.scroll-item .center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 0.625rem;
  margin-top: 1.09375rem;
}

.scroll-item .center .goods-img {
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 0.46875rem;
}

.goods-img .goodsImg {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

/* .goods-img img {
        -webkit-touch-callout: none;
        user-select: none;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    } */

.scroll-item .center .center-right {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.scroll-item .center .center-right .center-title {
  font-weight: 550;
  color: #1e2331;
  font-size: 0.8125rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 12.5rem;
}

.scroll-item .center .center-right .center-buy-info {
  margin-top: 0.71875rem;
  color: #9b9fa8;
  font-weight: 400;
  font-size: 0.75rem;
}

.scroll-item .center .center-right .center-num {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.375rem;
  color: #1e2331;
  font-weight: 400;
  font-size: 0.625rem;
}

.scroll-item .center .center-right .center-num .number {
  color: #9b9fa8;
  font-size: 0.8125rem;
}

.scroll-item .order-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.03125rem;
  height: 2rem;
}

.scroll-item .order-bottom .order-bottom-left {
  font-weight: 400;
  color: #1e2331;
  font-size: 0.875rem;
}

.scroll-item .order-bottom .order-bottom-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.625rem;
}

.scroll-item .order-bottom .order-bottom-right .info {
  background-color: #fff;
  border: 0.0625rem solid #a0a3ac;
  color: #a0a3ac;
}

.scroll-item .order-bottom .order-bottom-right .order-btn {
  width: 5.25rem;
  height: 2rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 400;
}

.scroll-item .order-bottom .order-bottom-right .submit {
  color: #fff;
  background: linear-gradient(90deg, #fe8754 0%, #fe5f4d 100%);
}

.scroll-item .order-bottom .order-bottom-right .btn {
  width: 5.25rem;
  height: 2rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 400;
}

.scroll-item .order-bottom .order-bottom-left .money {
  color: #fd4d20;
  font-weight: 550;
  font-size: 1.0625rem;
}
</style>
