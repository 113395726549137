<template>
  <div>
    <div class="support-center">
      <div class="support-search">
        <div class="suppport-title">
          <div class="st-upper">系统公告</div>
          <div class="st-sub">为您提供一站式的咨询和自助服务</div>
        </div>
        <!-- <div class="support-input-container">
          <input
            autocomplete="off"
            type="text"
            placeholder="请输入问题关键词"
            required="required"
            class="support-input"
          /><span class="search-btn"> 查一下 </span>
        </div> -->
      </div>
    </div>
    <div class="pc-common">
      <el-card shadow="never" style="margin-top: 20px">
        <div
          style="padding: 10px; margin-bottom: 10px"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div class="additional zone">
            <div>
              <div class="news-header">
                <div class="html-widget">
                  <p class="page-header">
                    <span style="box-sizing: border-box; font-size: 22px">
                      {{ item.cateName }}

                      <span style="font-size: 10px"></span>
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div class="widget clearfix news-row">
              <div class="section">
                <el-row>
                  <el-col
                    :span="12"
                    v-for="(content, index) in item.contentList"
                    :key="index"
                    style="margin-top: 10px"
                  >
                    <div
                      class="copy-group col-sm-6"
                      @click="toNoticeDetails(content)"
                    >
                      <div class="section-group-default">
                        <div class="article-title">
                          <h4>
                            <a target="_blank">
                              {{ content.contentTitle }}
                            </a>
                          </h4>
                        </div>
                        <!-- <div class="summary">
                            {{ content.contentDetail }}
                          </div> -->
                        <p class="sub-info">
                          <span class="text-muted publish-date">
                            发布时间： {{ content.datelineCreateReadable }}
                          </span>
                        </p>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="product-form">
          <div class="form-navigation">
            <div class="navigation-tag"></div>
            <div class="navigation-title">系统公告</div>
          </div>
          <div class="form-content" style="min-height: 720px">
            <el-divider></el-divider>

            <div class="notice-box">
              <div class="notice-list">
                <div class="list-content">
                  <div
                    :class="{
                      checkList: index === selectedIndex,
                      list: index !== selectedIndex,
                    }"
                    v-for="(item, index) in listData"
                    :key="index"
                    @click="clickIndex(item, index)"
                  >
                    <div class="check-tag"></div>
                    <div class="title">{{ item.cateName }}</div>
                  </div>
                </div>
                <div class="page">
                  分页
                </div>
              </div>
              <div class="notice-content" v-if="selectedIndex != undefined">
                <div class="title">
                  {{ listData[selectedIndex].contentList }}
                </div>
                <div class="time">
                  发布时间：{{ listData[selectedIndex].createTime }}
                </div>
                <div
                  class="content"
                  v-html="listData[selectedIndex].noticeDetail"
                ></div>
              </div>
            </div>
          </div>
        </div> -->
      </el-card>
    </div>
  </div>
</template>
<script>
import { queryNewsList } from "@/api/news";

export default {
  components: {
    // Header,
  },
  data() {
    return {
      queryParam: {
        noticeType: "1",
      },
      listData: [],
      selectedIndex: undefined,
    };
  },

  created() {
    this.initData();
  },
  methods: {
    /**
     * 查询首页公告
     */
    initData() {
      queryNewsList(this.queryParam).then((response) => {
        if (response.status == 0) {
          this.listData = response.result;
        }
      });
    },

    clickIndex(item, index) {
      this.selectedIndex = index;
    },

    /**
     * 展示公告详情
     */
    toNoticeDetails(row) {
      // this.$router.push({
      //   path: "mNoticeDetails",
      //   query: { noticeId: data.id },
      // });

      this.$router.push({ name: "noticeDetails", params: { id: row.id } });
    },

    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.natice {
  background-color: #ffffff;
}

.product-form {
  width: 100%;
  background-color: #fff;
}

.product-form .form-navigation {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 23px;
}

.product-form .form-navigation .navigation-tag {
  width: 6px;
  height: 22px;
  background-color: #df2124;
  margin-right: 10px;
}

.product-form .form-navigation .navigation-title {
  font-size: 18px;
  font-weight: 700;
  color: #494d51;
  line-height: 60px;
  position: relative;
}

/* .product-form .form-content {
  padding: 0px 23px;
} */

.notice-box {
  display: flex;
  padding-top: 10px;
}

.notice-box .notice-list {
  width: 300px;
}

.notice-box .notice-list .list-content {
  background-color: rgba(238, 238, 238, 0.38);
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 2px;
  height: 630px;
}

.notice-box .notice-list .list-content .list {
  margin-left: 50px;
  cursor: pointer;
  width: 300px;
}

.notice-box .notice-list .list-content .list .title {
  width: calc(100% - 50px);
  font-weight: 700;
  font-size: 14px;
  color: #222;
  line-height: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notice-box .notice-list .list-content .checkList {
  display: flex;
  align-items: center;
  background-color: #409eff;
  border-radius: 2px;
  height: 51px;
  width: 300px;
  cursor: pointer;
}

.notice-box .notice-list .list-content .checkList .title {
  width: calc(100% - 10px);
  font-weight: 700;
  font-size: 14px;
  color: #fff;
  line-height: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notice-box .notice-list .list-content .checkList .check-tag {
  margin-left: 15px;
  margin-right: 10px;
  width: 0;
  height: 0;
  border-left: 8px solid #ffffff;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

.notice-box .notice-list .page {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notice-box .notice-content {
  width: calc(100% - 380px);
  max-height: 600px;
  overflow-y: auto;
  margin-left: 50px;
  margin-right: 30px;
}

.notice-box .notice-content .title {
  text-align: center;
  font-size: 20px;
  color: #222;
}

.notice-box .notice-content .time {
  text-align: center;
  font-size: 12px;
  color: #9598a6;
  line-height: 50px;
}

.no-data {
  min-height: 740px;
}

.support-center {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #fff;
}

.support-center .support-search {
  display: inline-block;
  width: 100%;
  height: 260px;
  background: url(../../assets/image/support-bg.png) no-repeat top;
  background-size: cover;
  text-align: center;
  position: relative;
  top: -2px;
}

.support-center .support-search .suppport-title {
  display: inline-block;
  width: 100%;
  color: #fff;
  margin: 50px 0 30px;
}

.support-center .support-search .suppport-title .st-upper {
  font-size: 38px;
  font-weight: 700;
  line-height: 100px;
  letter-spacing: 0;
  color: #fff;
  text-shadow: 1.35px 1.35px 0 0 #002fa3;
}

.support-center .support-search .suppport-title .st-sub {
  font-size: 20px;
  font-weight: 500;
  text-shadow: 1px 1px 0 0 rgba(0, 47, 163, 0.5);
}

.support-center .support-search .support-input-container {
  width: 900px;
  height: 50px;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 110px auto;
}

.support-center .support-search .support-input-container .support-input {
  float: left;
  width: 770px;
  height: 50px;
  outline: none;
  border: none;
  padding: 0 12px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  text-rendering: auto;
  color: #333;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
  -webkit-rtl-ordering: logical;
  cursor: text;
  background-color: #fff;
  font-size: 16px;
}

/* 新的 */

.news-header {
  margin-bottom: 10px;
}
.news-header em {
  font-style: normal;
}
.news-header a {
  float: right;
  margin-top: 12px;
  border-bottom: 1px solid transparent;
}
.news-header a:hover,
.news-header a.active,
.news-header a:focus {
  text-decoration: none;
  color: #6a6c6c;
  /*border-bottom-color: #3338FF;*/
}
.news-header a span {
  color: #6a6c6c;
  font-size: 24px !important;
  line-height: 28px;
}
.news-header .view-all-icon {
  position: relative;
  top: 2px;
  margin-left: 15px;
  display: inline-block;
  width: 10px;
  height: 20px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAUCAYAAAC07qxWAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA39pVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ4IDc5LjE2NDAzNiwgMjAxOS8wOC8xMy0wMTowNjo1NyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo2NWNmOTMzZS1iMjE5LWI0NDgtYjY0NS04ZGRkODUyYzgzZWEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NkJBOTBEOTY4RDI1MTFFQTg4MTZDN0NBNTZEMUY1MTYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NkJBOTBEOTU4RDI1MTFFQTg4MTZDN0NBNTZEMUY1MTYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjUwYWQzNTM5LTc5MWMtOTE0Yy1iNjAwLTg0MWYwM2QwZjY0ZiIgc3RSZWY6ZG9jdW1lbnRJRD0iYWRvYmU6ZG9jaWQ6cGhvdG9zaG9wOmFkZTUwMzA2LTk2MTgtNzU0Ny05YjczLTE5NTAwZTRlZDc3NiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjxmBoAAAAD9SURBVHjafNK/S0JRGMbx6w8UXKRBcBBECHFoClpdFM0EB2locmy4XPoL2msUBGchcmkQNKGQ1N2hocGGHBwKiQIXB5Wk74FzQcT3PPDZHu6F9zwe23FmlmVVcWsZ4kUXN7BNRT8ucYAa5mhKX/zDBYZooCgVVVYo4RUPSEtFlQXOMEEHx1JR5Ren+MEzklJR5RN5rNFHTCqqfOhyCC+IeA2ne0MBcbybim428JiKR3jEF1JSMYEnLJHBt39PKYoegvrwU/ettxPWpQiyGG+Pwk1I/+5Qv9Bodz0qAbRwgjIG+2bmwz1yqKAtraeOc1zhzrRwdf1rPVwx/wIMAEKtLfYEDJcGAAAAAElFTkSuQmCC);
}
.news-row {
  padding: 20px;
  background: #fbfbfb;
  margin-top: 0;
  margin-bottom: 0;
}

.news-row .copy-group .section-group-default {
  padding: 20px;
  /* height: 210px; */
  background: #ffffff;
  border: 1px solid rgba(244, 244, 244, 1);
}
.news-row .article-title a {
  color: #353838;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 22px;
}
.news-row .article-title h4 {
  margin-top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.news-row .summary {
  color: #353838;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 24px;
  min-height: 72px;
}
.news-row .sub-info {
  font-size: 14px;
}
.news-row .sub-info .article-source {
  color: #6a6c6c;
  margin-right: 65px;
  min-width: 100px;
}
.news-row .sub-info .publish-date {
  color: #6a6c6c;
}
</style>
