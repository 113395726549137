<template>
  <div class="xcx-preview">
    <!-- <span class="el-icon-close" @click="$parent.showPreview = false"></span> -->
    <div
      class="xcx-module-container"
      v-for="(data, index) in list"
      :key="index"
    >
      <!--控件部份-->
      <div v-for="(item, index) in data.controlList" :key="index">
        <!--页面背景-->
        <pagebg :base="item.base"></pagebg>
      </div>

      <div class="xcx-module">
        <!--DIY组件部份-->
        <Module
          :moduleList="data.moduleList"
          :prev="true"
          ref="module"
          style="min-height: calc(100vh - 300px)"
        />
      </div>
    </div>

    <!-- 首页弹框 -->
    <el-dialog
      :destroy-on-colse="true"
      title="系统通知"
      :visible.sync="noticeVisible"
      width="35%"
      append-to-body
    >
      <div class="ql-editor" v-html="noticeContent" />
    </el-dialog>
  </div>
</template>

<script>
import Module from "@/components/Module/pc/Module.vue";
import pagebg from "@/pages/template/pc/module/pagebg.vue";
import { getWebTemplate, getIndexNotice } from "../api/index";
// import { queryNoticeList } from "@/api/index";

export default {
  name: "index",
  components: { Module, pagebg },
  data() {
    return {
      list: [],
      noticeVisible: false,
      noticeContent: undefined,
      loginStatus: false,
      noticeList: [],
    };
  },

  created() {
    this.initData();
  },
  methods: {
    initData() {
      let data = {};
      getWebTemplate(data).then((response) => {
        if (response.status == 0) {
          this.list = JSON.parse(response.data);
        }
      });

      //延迟500毫秒执行方法
      setTimeout(() => {
        this.getNotice();

        //加载底部栏
        // this.queryFooter();
      }, 500);
    },

    /**
     * 查询首页弹框公告
     */
    getNotice() {
      getIndexNotice().then((response) => {
        if (response.result) {
          this.noticeVisible = true;
          this.noticeContent = response.result.content;
          console.log(response);
        }
      });
    },

    //加载底部栏
    // queryFooter() {
    //   var data = {};
    //   new Promise((resolve, rejust) => {
    //     queryNoticeList(data)
    //       .then((res) => {
    //         this.noticeList = res.result.list;
    //         resolve();
    //       })
    //       .catch((error) => {
    //         rejust(error);
    //       });
    //   });
    // },

    /**
     * 展示公告详情
     */
    toNoticeDetails(data) {
      this.$router.push({
        path: "noticeDetails",
        query: { noticeId: data.id },
      });
    },
  },
};
</script>
<style scoped>
/deep/.el-dialog {
  border-radius: 10px;
}
</style>
