<template>
  <div class="invite-code">
    <van-nav-bar
      title=""
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="content">
      <div class="code-section">
        <div class="code-info">
          <div class="code-label">我的邀请链接</div>
          <!-- <div class="code">{{ inviteCode }}</div> -->
        </div>
         <!-- <br/> <br/> -->
        <!-- <div class="qrcode">
          <img :src="inviteUrlQrImageUrl" alt="邀请二维码" />
        </div> -->
      </div>
     
      <div class="link-section">
        <!-- <div class="link-label">邀请链接：</div> -->
        <div class="link-input">
          <input type="text" v-model="inviteUrl" readonly />
          <button @click="copyInviteLink">复制</button>
        </div>
      </div>
    </div>

        <van-cell>说明: </van-cell>
        <van-cell>1、由您邀请注册的用户每次下单成交的订单，你可以得到一定比例的佣金；</van-cell>
        <van-cell>2、佣金可以自由结算和提现；</van-cell>
        <van-cell>3、一级下家：你直接邀请注册的用户；二级下家：你的下家邀请注册的用户；三级下家：你下家的下家邀请注册的用户；</van-cell>
        <van-cell>4、上家分佣比例：一级下家70%，二级下家20%，三级下家10%；</van-cell>
        <van-cell>5、举例：如果用户A购买一笔总额¥100的订单，产生了¥10佣金。如果A是你的一级下家，那么你将得到¥10 x 70% = ¥7.00；</van-cell>
        <van-cell>6、如果A是你的二级下家，那么你将得到¥10 x 20% = ¥2.00；</van-cell>

  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
    //   inviteCode: "ABC123",
    //   inviteLink: "",
    //   inviteQRCode: "https://www.example.com/qrcode.png",
    };
  },
  computed: {
    ...mapState({
      balanceTotal: (state) => state.balance.balanceTotal,
      username: (state) => state.user.username,
      avatarPicUrl: (state) => state.user.avatarPicUrl,
      email: (state) => state.user.email,
      phone: (state) => state.user.phone,
      inviteCode: (state) => state.user.inviteCode,
      inviteUrl: (state) => state.user.inviteUrl,
      inviteUrlQrImageUrl: (state) => state.user.inviteUrlQrImageUrl,
    }),
  },
  created() {
        document.title = "推广码";

    this.$store.dispatch("user/info");
  },
  mounted() {
    // 生成邀请链接
    // this.inviteLink = `${window.location.origin}/register?inviteCode=${this.inviteCode}`;
  },
  methods: {
    copyInviteLink() {
      // 复制邀请链接
      const input = document.createElement("input");
      input.setAttribute("value", this.inviteUrl);
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      alert("已复制到剪贴板");
    },

        /**
     * 返回主页
     */
    onClickLeft() {
      this.$router.push("/my");
    },
  },
};
</script>

<style scoped>
/* 页面整体布局 */
.invite-code {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  background-color: #262626;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px;
}

.description {
  margin-bottom: 24px;
  text-align: center;
  color: #666;
}

.code-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.code-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.code-label {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.code {
  font-size: 28px;
  font-weight: bold;
  margin-left: 10px;
  color: #007aff;
  text-transform: uppercase;
}

.qrcode img {
  width: 120px;
  height: 120px;
}

.link-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.link-label {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-right: 16px;
}

.link-input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.link-input input {
  flex-grow: 1;
  margin-right: 8px;
  padding: 12px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  box-sizing: border-box;
}

.link-input button {
  height: 42px;
  border-radius: 4px;
  background-color: #007aff;
  color: #fff;
  font-size: 14px;
  padding: 0 24px;
  border: none;
  outline: none;
  cursor: pointer;
}

.link-input button:hover {
  background-color: #0062cc;
}

@media (max-width: 768px) {
  .content {
    padding: 24px;
  }

  .description {
    margin-bottom: 16px;
    font-size: 14px;
  }

  .code {
    font-size: 24px;
  }
}
</style>