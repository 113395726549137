<template>
  <div id="module">
    <section
      class="section-module"
      v-for="(element, index) in moduleList"
      :key="index"
    >
      <div
        class="xcx-module-item"
        :class="selectIndex === index ? 'on' : ''"
        @click.stop="moduleClick(index)"
        @mouseenter="mouseovers(index)"
        @mouseleave="mouseout"
      >
        <!-- PC端 -->
        <!-- <pHead :module="element" v-if="element.type == 201" /> -->
        <pBanner :module="element" v-if="element.type == 202" />
        <pGoods :module="element" v-if="element.type == 204" />
        <pRecommend :module="element" v-if="element.type == 205" />
        <pNotice :module="element" v-if="element.type == 206" />
        <guanggao :module="element" v-if="element.type == 208" />

        <!-- <pFooter :module="element" v-if="element.type == 207" /> -->
      </div>
    </section>
  </div>
</template>

<script>
// import pHead from "@/pages/template/pc/module/head/common.vue";
import pRecommend from "@/pages/template/pc/module/recommend/common.vue";
import pGoods from "@/pages/template/pc/module/goods/common.vue";
import pBanner from "@/pages/template/pc/module/banner/common.vue";
import pNotice from "@/pages/template/pc/module/notice/common.vue";
import guanggao from "@/pages/template/pc/module/guanggao/common.vue";

// import pFooter from "@/pages/template/pc/module/footer/common.vue";

export default {
  name: "Module",
  props: {
    moduleList: { type: Array },
    prev: { default: false },
  },
  components: {
    // pHead,
    pRecommend,
    pGoods,
    pBanner,
    pNotice,
    guanggao,
  },
  data() {
    return {
      placeholder: false,
      selectIndex: "", //选中
      activeIndex: "", //当前活动active
    };
  },

  methods: {
    //模块点击
    moduleClick(index) {
      this.selectIndex = index;
      this.$emit("moduleClick", index);
    },

    //鼠标移入模块
    mouseovers(index) {
      let top = event.target.getBoundingClientRect().top;
      this.$emit("moduleMouseovers", { top: top, index: index });
    },

    //鼠标离开模块
    mouseout() {
      this.$emit("moduleMouseout");
    },
  },
};
</script>
