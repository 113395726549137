<template>
  <div>
    <van-nav-bar
      title="资金明细"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />

    <div>
      <van-collapse v-model="activeNames">
        <van-collapse-item
          v-for="item in tableData"
          :key="item.id"
          :value="item.amount"
        >
          <template #title>
            <div>{{ item.eventText }}</div>
          </template>
          <van-cell title="订单号" :value="item.serialNum" />
          <van-cell title="收支类型" :value="item.typeText" />
          <van-cell title="备注" :value="item.remarks" />
          <van-cell title="交易时间" :value="item.datelineReadable" />
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      activeNames: ["1"],

      tableData: [],
      loadingState: false,
      finished: false,
      queryParam: {
        // pageNum: 1,
        // pageSize: 10,
      },
    };
  },
  computed: {
    ...mapState({
      balanceTotal: (state) => state.balance.balanceTotal,
      totalWithdrawRejected: (state) => state.balance.totalWithdrawRejected,
      totalRechargeSuccess: (state) => state.balance.totalRechargeSuccess,
      balanceCanWithdraw: (state) => state.balance.balanceCanWithdraw,
      totalWithdrawing: (state) => state.balance.totalWithdrawing,
      totalWithdrawSuccess: (state) => state.balance.totalWithdrawSuccess,
      tableDatas: (state) => state.balance.list,
      statusText: (state) => state.user.statusText,
    }),
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$store.dispatch("balance/list", this.queryParam).then(() => {
        this.tableData = this.tableData.concat(this.tableDatas);
      });
    },

    //跳转到我的页面
    onClickLeft() {
      console.log("_-------------");
      this.$router.push("/my");
    },
  },
};
</script>

<style scoped>
.custom-list {
  margin: 5px;
}

.list-item {
  border: none;
  /* padding: 12px; */
  /* background-color: #fff; */
  /* border-radius: 8px; */
  /* margin-bottom: 10px; */
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}

.event {
  font-size: 16px;
  color: #333;
}

.type {
  font-size: 14px;
  color: #666;
}

.date {
  font-size: 12px;
  color: #999;
}

.amount {
  font-size: 18px;
  font-weight: bold;
  text-align: right;
}

.income {
  color: green;
}

.expense {
  color: red;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* 明细列表 */
.detail {
  width: 100%;
  /* width: 6.94rem; */
  margin: 0 auto;
  background: linear-gradient(to right, #ffffff, #ffffff);
  padding: 10px 10px 10px 10px;
  /* border-radius: 0.15rem; */
  margin-top: 10px;
}

.detail .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail .title h4 {
  font-size: 16px;
  color: #000000;
}

.detail .title span {
  color: #babcc6;
  font-size: 14px;
}

.detail .list {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding-top: 12px;
}

.detail .list ul {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.detail .list ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  position: relative;
  border-bottom: 1px solid #f8f8f8;
}

.detail .list ul li .l {
  display: flex;
  align-items: center;
}

.detail .list ul li .l img {
  width: 25px;
  height: 25px;
}

.detail .list ul li .l .info {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
}

.detail .list ul li .l .info p {
  font-size: 14px;
  color: #000000;
}

.detail .list ul li .l .info span {
  font-size: 12px;
  color: #8f92a1;
  padding-top: 0px;
}

.detail .list ul li .r {
  display: flex;
  align-items: center;
}

.detail .list ul li .r span {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  padding-right: 10px;
}

.detail .list ul li .r i {
  /* flex-shrink: 0; */
  /* display: inline-block; */
  /* width: 20px; */
  /* height: 0.4rem; */
  /* line-height: 0.4rem; */
  text-align: center;
  border-radius: 100%;
  font-size: 12px;
  color: #fff;
  background: #9698a5;
}
</style>
