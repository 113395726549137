<template>
  <div class="module-common pc-common">
    <div class="index-1">
      <div class="left fl" :style="moduleBgStyle">
        <div class="tj-lable">爆款推荐</div>
        <p>商 · 品 · 区</p>
        <!-- <a href="/home/goods/getgoodsbyhot.html">
              <button>更多+</button>
            </a> -->
      </div>
      <div class="right fr">
        <el-carousel indicator-position="none" trigger="click" height="150px">
          <el-carousel-item
            v-for="(data, index) in this.slideGroups"
            :key="index"
          >
            <div class="carousel-list">
              <div
                class="carousel-li"
                v-for="(item, index) in data"
                :key="index"
                @click="toProductDetails(item)"
              >
                <!-- 轮播项内容 -->
                <img :src="item.picUrl" />
                <div class="bt" style="height: 50px">
                  {{ item.productName }}
                </div>
                <div>
                  <span
                    v-html="$decimalPrice(item.price)"
                    style="margin-left: 6px"
                  ></span>

                  <!-- <button type="primary" round size="small">购买</button> -->
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  props: ["module"],
  components: {},
  data() {
    return {
      webLogo: "",
      loginStatus: false,
      goodsName: "",
      catesList: [],
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      recommendList: (state) => state.index.recommendList,
    }),
    slideGroups() {
      // 将轮播项按照每行展示的数量进行分组
      console.log(this.catesList);
      const groups = [];
      for (let i = 0; i < this.catesList.length; i += 3) {
        groups.push(this.catesList.slice(i, i + 3));
      }
      console.log(groups);
      return groups;
    },
    moduleBgStyle() {
      return {
        background: `linear-gradient(122235deg, ${this.module.moduleBg.bgColor}, ${this.module.moduleBg.fzColor})`,
        color: this.module.moduleBg.fontColor,
      };
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      let data = { offset: 0, limit: 10 };
      this.$store.dispatch("index/queryRecommendList", data).then(() => {
        this.catesList = this.recommendList;
      });
    },

    //跳转商品详情界面
    toProductDetails(v) {
      this.$router.push({
        path: "pgoodsDetail",
        query: { product_id: v.id },
      });
    },
  },
};
</script>

<style>
/* 公共样式结束 */

.index-1 {
  margin-top: 18px;
  width: 100%;
  height: 169px;
  background-color: #fff;
  border-radius: 10px;
}

.index-1 .left {
  /* background: url(../images/index-1.jpg) no-repeat; */
  width: 10%;
  height: 169px;
  text-align: center;
  padding-top: 28px;
  border-radius: 6px;
}

.index-1 .left .tj-lable {
  font-size: 21.96px;
  /* color: #7c17e0; */
  margin-bottom: 15px;
}

.index-1 .left p {
  /* color: #7c17e0; */
  font-size: 20px;
  margin-bottom: 20px;
}

.index-1 .left button {
  width: 79px;
  height: 37px;
  background-color: #781ee2;
  font-size: 14px;
  color: #ffffff;
  border-radius: 20px;
  letter-spacing: 1px;
  border: none;
  outline: none;
  cursor: pointer;
}

.index-1 .right {
  width: 997px;
  height: 100%;
  padding: 11px 60px 0px 13px;
  margin-left: 32px;
  border-left: 1px solid #e5e5e5;
  padding: 30px 0px;
  margin-left: 0;
  border: none;
  width: 89%;
}

.index-1 .right ul li {
  display: flex;
  height: 48px;
  line-height: 48px;
  border-bottom: 1px dashed #e5e5e5;
  position: relative;
}

.index-1 .right ul li p {
  font-family: pfm;
  font-size: 14px;
  color: #333333;
  width: 550px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.index-1 .right ul li .jiage {
  height: 20px;
  overflow: hidden;
  margin: 14px 15px;
  display: flex;
  align-items: center;
  background-color: #ffe1e9;
  border-radius: 10px;
  border-top-left-radius: 0;
}

.index-1 .right ul li .jiage span {
  color: #e62258;
  font-family: pfm;
  padding: 0 3px;
}

.index-1 .right ul li .jiage-1 {
  background-color: #f3fff8;
}

.index-1 .right ul li .jiage-1 span {
  color: #32b16c;
}

.index-1 .right ul li em {
  font-size: 14px;
  font-family: pfm;
  color: #999;
  text-decoration: line-through;
}
.index-1 .right ul li .shij {
  text-decoration: none;
  position: absolute;
  right: 0;
}

.index-1 .right ul li .quq {
  position: absolute;
  left: -19px;
  top: 50%;
  transform: translate(-0%, -50%);
}

.carousel-list {
  /* display: flex; */
  justify-content: space-between;
}

.carousel-list .carousel-li {
  /* width: 320px; */
  height: 112px;
  display: inline-block;
  line-height: 1;
  border-bottom: none;
  border: 1px solid #eee;
  margin-right: 30px;
}

.carousel-list .carousel-li img {
  float: left;
  width: 110px;
  height: 110px;
}

.carousel-list .carousel-li .bt {
  float: left;
  width: 188px;
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin: 10px;
  line-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.carousel-list .carousel-li p {
  width: 188px;
  margin: 10px;
  float: left;
}

.carousel-list .carousel-li p span {
  color: #e7125c;
  font-size: 18px;
}

.carousel-list .carousel-li p button {
  width: 43px;
  height: 21px;
  background-color: #e62258;
  border-radius: 15px;
  border: none;
  outline: none;
  float: right;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}

.fl {
  float: left;
  font-size: 18px;
}

.fr {
  float: right;
}

/deep/.el-dialog {
  border-radius: 5px;
}
</style>
